import React, { useEffect, useState, useMemo } from "react";
import moment from "moment";
import "./style.scss";
import AttachmentIcon from "../../assets/icons/components/AttachmentIcon";
import { _global } from "../../helpers";
import { RightArrowIcon, DownArrowIcon, ArrowUpIcon, ArrowDownIcon, ArrowUpDownIcon } from "../../assets/icons/components";
import { useDispatch, useSelector } from "react-redux";
import SkeletonText from "../skeletons/SkeletonText";
import actions from "./../../actions/action.conversations";
import { downloadAttachment } from "./../../actions/action.messageList";
import { unstable_batchedUpdates } from "react-dom";
import { Alert } from "reactstrap";
import ReactMarkdown from "react-markdown";
import rehypeRaw from 'rehype-raw';
import { changeToTitleCase } from "../../utils/convertionUtils";

const ConversationTable = ({ conversations = { Items: [] }, member, openReplyModal, filter, setFilter }) => {
  const [search, setSearch] = useState("");

  const [order, setOrder] = useState(true);
  const [sortingBy, setSortingBy] = useState("createdTimestamp");

  const [savedConversations, /*setSavedConversation*/] = useState([]);
  const [sortedConversation, setSortedConversation] = useState([]);

  const [showMessages, setShowMessages] = useState(false);

  //pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [conversationPerPage, setConversationPerPage] = useState(10);

  const indexOfLastConversation = currentPage * conversationPerPage;
  const indexOfFirstConversation = indexOfLastConversation - conversationPerPage;
  const currentConversations = sortedConversation.slice(indexOfFirstConversation, indexOfLastConversation);

  const dispatch = useDispatch();
  const { messages, loading, error, loadingMessages } = useSelector((state) => state.conversations);

  const filterSortedConversation = useMemo(() => {
    return () => {
      if (search) {
        setSortedConversation(sortedConversation.filter((con) => con.conversationId.toLowerCase().includes(search.toLowerCase())));
      } else {
        setSortedConversation(savedConversations);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    //  console.log(search)
    filterSortedConversation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    setSearch("");
    setOrder(false);
    let sortedList = [...conversations.Items].sort((a, b) => b.lastMessage.createdTimestamp - a.lastMessage.createdTimestamp);
    setSortedConversation(sortedList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversations.fetchedAt]);

  const sortTable = (sorting) => {
    const getContent = (path, obj, separator = ".") => {
      let properties = Array.isArray(path) ? path : path.split(separator);
      return properties.reduce((prev, curr) => prev && prev[curr], obj);
    };

    unstable_batchedUpdates(() => {
      //to check to see which prperty is being sorted
      //sortedConversation.forEach(obj => console.log(getContent(sorting.attribute, obj)));
      setOrder(sorting.order);
      setSortingBy(sorting.attribute);
      if (sorting.order) {
        setSortedConversation(
          [...sortedConversation].sort(
            (a, b) =>
              (typeof getContent(sorting.attribute, a) === "number" && getContent(sorting.attribute, b) - getContent(sorting.attribute, a)) ||
              (getContent(sorting.attribute, b) > getContent(sorting.attribute, a) ? 1 : -1)
          )
        );
      }
      //desc
      else {
        setSortedConversation(
          [...sortedConversation].sort(
            (a, b) =>
              (typeof getContent(sorting.attribute, a) === "number" && getContent(sorting.attribute, a) - getContent(sorting.attribute, b)) ||
              (getContent(sorting.attribute, b) < getContent(sorting.attribute, a) ? 1 : -1)
          )
        );
      }
    });
  };

  const setFilterHandler = (tabName) => {
    unstable_batchedUpdates(() => {
      setShowMessages(false);
      setFilter(tabName);
      setCurrentPage(1);
    });
  };

  const showMessagesHandler = (conversation) => {
    if (conversation.conversationId === showMessages) {
      //hdie the messages
      setShowMessages(false);
    } else {
      setShowMessages(conversation.conversationId);
      dispatch(actions.getMessagesByConversationIdAction(conversation));
    }
  };

  const changeStatusHandler = (conversation, status) => {
    dispatch(actions.changeConversationStatusAction({ ...conversation, status }, filter));
  };

  const showAttachment = (message) => {
    return (
      <div className="email-attachments-wrapper">
        {message.attachments &&
          message.attachments.map((attachment) => {
            return (
              <div key={attachment.Key} className="email-attachment">
                <div className="top">
                  <div className="card-icon">{_global.getIconBasedOnFileType(attachment.type)}</div>
                  <div className="file-desc">
                    <p>{attachment.name}</p>
                    <p style={{ fontSize: "x-small" }}>{_global.niceBytes(attachment.size)}</p>
                  </div>
                </div>
                <div className="buttom">
                  <p onClick={() => dispatch(downloadAttachment({ Key: attachment.Key, filename: attachment.name }))}>Download</p>
                </div>
              </div>
            );
          })}
      </div>
    );
  };

  const showMessagesPlaceHodler = (count) => {
    return [...Array(count)].map((e, i) => (
      <div className="message-card" key={i}>
        <div className="message-header">
          <SkeletonText style={{ width: "20%", height: "20px" }} />
          <SkeletonText style={{ width: "20%", height: "20px" }} />
        </div>
        {/* <SkeletonText style={{ width: '30%', height: '30px' }} /> */}
        <SkeletonText style={{ width: "100%", height: "20px" }} />
      </div>
    ));
  };

  const pagination = (number) => {
    setCurrentPage(number);
  };

  return (
    <div className="conversationTable">
      <Alert color="danger" isOpen={error ? true : false} style={{ marginTop: "20px" }}>
        {error}
      </Alert>
      <div className="tab-options">
        <div className={`tab-action ${filter === "inbox" ? " selected" : null}`} onClick={() => setFilterHandler("inbox")}>
          Inbox
        </div>
        <div className={`tab-action ${filter === "complete" ? " selected" : null}`} onClick={() => setFilterHandler("complete")}>
          Complete
        </div>
      </div>

      <div className='content-wrapper'>
        <Pagination
          totalConversations={sortedConversation.length}
          currentConversations={currentConversations}
          conversationPerPage={conversationPerPage}
          indexOfFirstConversation={indexOfFirstConversation}
          pagination={pagination}
          setConversationPerPage={setConversationPerPage}
          currentPage={currentPage}
        />

        <table>
          <thead>
            <tr>
              <th></th>
              <th
                onClick={() => {
                  sortTable({ order: !order, attribute: "lastMessage.createdTimestamp" });
                }}
              >
                Date
                {sortingBy === "lastMessage.createdTimestamp" ? order ? <ArrowUpIcon /> : <ArrowDownIcon /> : <ArrowUpDownIcon />}
              </th>
              <th></th>
              <th>MEMBER NAME</th>
              <th>CLIENT NAME</th>
              <th>MEMBER ID</th>
              <th>VENDOR</th>
              <th>MEMBER EMAIL</th>
              <th>PHONE</th>
              <th style={{ width: '400px' }}>subject</th>
            </tr>
          </thead>
          <tbody>
            {currentConversations.map((conversation, index) => {
              return (
                <React.Fragment key={conversation.conversationId}>
                  <tr onClick={() => showMessagesHandler(conversation)} className={showMessages === conversation.conversationId ? 'selected-row' : ''}>
                    <td className="icon-arrow">
                      {conversation.conversationVersion > 1 ? showMessages === conversation.conversationId ? <DownArrowIcon /> : <RightArrowIcon /> : null}
                    </td>
                    <td>
                      {moment(conversation.lastMessage.createdTimestamp).format("MM/DD/YYYY")}
                    </td>
                    <td>
                      {
                        (!isNaN(conversation.lastMessage.attachments) && conversation.lastMessage.attachments > 0) ||
                        (Array.isArray(conversation.lastMessage.attachments) && conversation.lastMessage.attachments.length) 
                          ? <AttachmentIcon /> 
                          : ""
                      }
                    </td>
                    <td>
                      {
                        conversation.member 
                          ? changeToTitleCase(conversation.member.firstName + " " + conversation.member.lastName) 
                          : member 
                            ? changeToTitleCase(member.name) 
                            : null
                      }
                    </td>
                    <td>
                      {(member?.clientName || conversation?.member?.clientName) ?? '-'}
                    </td>
                    <td>
                      {member.sequencedMemberId || (conversation.member && conversation.member.sequencedMemberId)}
                    </td>
                    <td>
                      {conversation?.member?.memberHccId || member?.memberHccId ? 'HealthRules Payer' : "Javelina"}
                    </td>
                    <td>
                      {member.email || (conversation.member && conversation.member.emailAddress)}
                    </td>
                    <td>
                      {_global.formatPhoneNumber(member.phone || (conversation.member && conversation.member.phoneNumber))}
                    </td>
                    <td>{conversation.topic}</td>
                  </tr>

                  {showMessages === conversation.conversationId ? (
                    <tr className="expanded">
                      <td colSpan={8}>
                        <div className="message-cards">
                          {messages && messages.Items.length
                            ? messages.Items.map((message, index) => (
                              <div className="message-card" key={message.messageId + index}>
                                <div className="message-header">
                                  <div className="senderFullName"><p>{message.senderFullName}</p></div>

                                  <div className="createdTimestamp">{moment(message.createdTimestamp).format("MMMM Do, YYYY hh:mm a")}</div>
                                </div>
                                {message.attachments ? showAttachment(message) : null}
                                <div className="message-body">
                                  <ReactMarkdown rehypePlugins={[rehypeRaw]} children={message.message.replace(/{{break}}/g, "<br />")} />
                                </div>
                              </div>
                            ))
                            : showMessagesPlaceHodler(conversation.conversationVersion - 1)}
                          {loadingMessages && showMessagesPlaceHodler(1)}

                          {messages && messages.Items.length !== conversation.conversationVersion && messages.LastEvaluatedKey && (
                            <div className="load-more-btn" onClick={() => dispatch(actions.getMessagesByConversationIdAction(conversation, messages.LastEvaluatedKey))}>
                              Load more...
                            </div>
                          )}
                          <div className="action-row">
                            <div className="reply-btn-wrapper">
                              <button
                                className="btn btn-transparent btn-reply"
                                onClick={() => {
                                  openReplyModal(conversation);
                                }}
                              >
                                <span className="icon icon-corner-up-left" />
                                Reply
                              </button>
                            </div>
                            {filter === "inbox" && (
                              <div
                                className="move-message-container"
                                onClick={() => {
                                  changeStatusHandler(conversation, "COMPLETE");
                                }}
                              >
                                <input type="checkbox" className="move-message-checkbox" />
                                <i className={`icon icon-circle icon-circle-unchecked`} />
                                <label className="move-message-label">Move to complete</label>
                              </div>
                            )}
                          </div>
                        </div>
                      </td>
                    </tr>
                  ) : null}
                </React.Fragment>
              );
            })}
          </tbody>
        </table>

        {conversations.LastEvaluatedKey && (
          <div
            className="load-more-btn"
            onClick={() => {
              setShowMessages(false);
              if (member.memberUuid) {
                dispatch(actions.getConversationsByRecipientIdAction(member.memberUuid, conversations.LastEvaluatedKey, filter));
              } else {
                if (filter === "inbox") {
                  dispatch(actions.getConversationsByStatusAction("CSR_ACTION_REQUIRED", conversations.LastEvaluatedKey, filter));
                } else if (filter === "complete") {
                  dispatch(actions.getConversationsByStatusAction("COMPLETE", conversations.LastEvaluatedKey, filter));
                }
              }
            }}
          >
            {loading ? "Loading" : " Load more..."}
          </div>

        )}
      </div>
    </div>
  );
};

export default ConversationTable;

const Pagination = ({ conversationPerPage, currentConversations, totalConversations, indexOfFirstConversation, pagination, setConversationPerPage, currentPage }) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalConversations / conversationPerPage); i++) {
    pageNumbers.push(i);
  }
  return (
    <nav className='pagination-navbar'>
      <span>      Records: {indexOfFirstConversation + 1} - {+indexOfFirstConversation + +currentConversations.length} / {totalConversations}</span>

      <select
        id="dropdown"
        onChange={(e) => {
          pagination(1);
          setConversationPerPage(e.target.value);
        }}
      >
        <option value="10">10</option>
        <option value="25">25</option>
        <option value="50">50</option>
        <option value="100">100</option>
      </select>
      <span>per page</span>
      <span>    {currentPage} / {pageNumbers.length}</span>

      <div className='pagination-arrow-botton-wrapper'>
        <div onClick={() => currentPage <= 1 ? null : pagination(currentPage - 1)}>
          <i className="icon icon-chevron-left" />
        </div>
        <div onClick={() => currentPage < pageNumbers.length ? pagination(currentPage + 1) : null}>
          <i className="icon icon-chevron-right" />
        </div>
      </div>

    </nav>
  );
};
